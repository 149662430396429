import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const Root = styled.div`
  margin-bottom: .75rem;
`;

export const InputLabel: React.FC<{htmlFor: string}> = ({ htmlFor, children }) => (
  <Root>
    <label htmlFor={htmlFor}>
      <Text>{children}</Text>
    </label>
  </Root>
);
