import {Route} from 'react-router-dom';
import { HasValidSignupToken } from './has_valid_signup_token/has_valid_signup_token';
import { PartnerSignup } from './partner_signup/partner_signup';
import { SignupForm } from './signup_form/signup_form';

export const Signup: React.FC = () => {
  return <>
    <Route exact path="/signup">
      <HasValidSignupToken>
        <SignupForm></SignupForm>
      </HasValidSignupToken>
    </Route>
    <Route path="/signup/partner/:partnerName">
      <PartnerSignup></PartnerSignup>
    </Route>
  </>
};