import { FormEvent, useCallback, useState } from 'react';
import { Form, Input, Typography } from 'antd';
import styled from 'styled-components';
import { Button } from 'antd';
import { useHistory } from 'react-router';

import { InputLabel } from '../../input_label/input_label';
import { InputContainer } from '../../input_container/input_container';
import { ApiClient } from '../../../api_client/api_client';
import { storeToken } from '../../../utils/auth_util';
import { SignupResponse } from '../../../api_client/declarations';
import { CreateMerchantStatus } from '../../../declarations';
import { AuthPage } from '../../auth_page/auth_page';
import { useQuery } from '../../../hooks/query';
import {
  PasswordValidation,
  PASSWORD_SCHEMA,
} from '../../password_validation/password_validation';
import { Center } from '../../center/center';
import { RenderablePartner } from '../../../utils/partner_util';

const apiClient = new ApiClient();

const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
  gap: 1rem;
`;

export interface SignupFormProps {
  partner?: RenderablePartner;
}

export const SignupForm: React.FC<SignupFormProps> = ({ partner }) => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [adminPasscode, setAdminPasscode] = useState('');
  const [error, setError] = useState('');
  const [inFlight, setInFlight] = useState(false);

  const isValid = useCallback(() => {
    const allFieldsPopulated: boolean = Boolean(
      username && password && email && merchantName && adminPasscode,
    );
    return allFieldsPopulated && PASSWORD_SCHEMA.validate(password);
  }, [username, password, email, merchantName, adminPasscode]);

  const handleSubmit = async () => {
    setInFlight(true);
    const response: SignupResponse = await apiClient.signup({
      username,
      password,
      email,
      merchantName,
      adminPasscode,
      marketPartner: partner?.name,
    });
    if (response.status !== CreateMerchantStatus.SUCCESS) {
      setInFlight(false);
      if (response.status === CreateMerchantStatus.FAILED_USERNAME_EXISTS) {
        setError('Username already exists');
        return;
      }
      if (response.status === CreateMerchantStatus.FAILED_EMAIL_EXISTS) {
        setError('Email already exists');
        return;
      }
      setError('Unable to create account. Please try again.');
      return;
    }
    // Runtime error, should not occur.
    if (!response.token) {
      setInFlight(false);
      setError(
        'Unable to authenticate after creating account. Please try logging in.',
      );
      return;
    }
    storeToken(response.token);
    history.push('/dashboard/locations');
  };

  return (
    <AuthPage>
      <Typography.Title level={4}><Center>Sign Up</Center></Typography.Title>
      {partner && (
        <Typography.Paragraph><Center>(Market partner: {partner?.label})</Center></Typography.Paragraph>
      )}
      <Form onFinish={handleSubmit}>
        <InputContainer>
          <InputLabel htmlFor="username">Username:</InputLabel>
          <Input
            size="large"
            id="username"
            value={username}
            onChange={e => void setUsername(e.target.value)}></Input>
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="password">Password:</InputLabel>
          <Input.Password
            size="large"
            id="password"
            value={password}
            onChange={e => void setPassword(e.target.value)}></Input.Password>
        </InputContainer>
        <PasswordValidation password={password}></PasswordValidation>
        <InputContainer>
          <InputLabel htmlFor="email">Email:</InputLabel>
          <Input
            size="large"
            type="email"
            id="email"
            value={email}
            onChange={e => void setEmail(e.target.value)}></Input>
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="merchant-name">Merchant Name:</InputLabel>
          <Input
            size="large"
            id="merchant-name"
            value={merchantName}
            onChange={e => void setMerchantName(e.target.value)}></Input>
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="admin-passcode">
            Admin Passcode:
            <br />
            <Typography.Text type="secondary">
              (Used to access the admin dashboard)
            </Typography.Text>
          </InputLabel>
          {/* TODO: add validation to passcode */}
          <Input.Password
            size="large"
            id="admin-passcode"
            value={adminPasscode}
            onChange={e =>
              void setAdminPasscode(e.target.value)
            }></Input.Password>
        </InputContainer>
        <Buttons>
          <Button
            loading={inFlight}
            disabled={!isValid()}
            type="primary"
            size="large"
            htmlType="submit">
            Sign up
          </Button>
          <Button
            type="link"
            size="large"
            htmlType="button"
            onClick={() => history.push('/login')}>
            Login
          </Button>
        </Buttons>
        {error && (
          <Typography.Paragraph style={{ textAlign: 'right' }} type="danger">
            {error}
          </Typography.Paragraph>
        )}
      </Form>
    </AuthPage>
  );
};
