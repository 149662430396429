import { Typography } from "antd";
import { AuthPage } from "../auth_page/auth_page";

export const ClosedBeta: React.FC = () => {
  return <AuthPage>
    <Typography.Title level={3}>
      Thanks for your interest!
    </Typography.Title>
    <Typography.Paragraph>
      SwiftExpo.io is currently in closed beta.
    </Typography.Paragraph>
    <Typography.Paragraph>
      Please fill out <a href="https://6wbb9em9j6f.typeform.com/to/CxfxinAl">this form</a> if you're interested in trying out the beta.
    </Typography.Paragraph>
  </AuthPage>
};