import { Typography } from 'antd';
import { useEffect, useState } from 'react';

import { CardInput } from '../card_input/card_input';
import { ApiClient } from '../../../api_client/api_client';
import { RouteComponentProps, withRouter } from 'react-router';
import { Spinner } from '../../spinner/spinner';

const { Paragraph } = Typography;

const apiClient = new ApiClient();

export interface SetupPaymentProps extends RouteComponentProps<{}> {
  /**
   * The path to redirect to once the setup is either completed or opted-out.
   */
  onCompleteRedirectPath: string;
}

export const SetupPayment = withRouter(
  ({ onCompleteRedirectPath, history }: SetupPaymentProps) => {
    const [setupIntentSecret, setSetupIntentSecret] = useState('');
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
      (async () => {
        setProcessing(true);
        const clientSecret = await apiClient.createStripeSetupIntent();
        if (clientSecret) {
          setSetupIntentSecret(clientSecret);
        } else {
          setError('Error initializing payment prompt. Please refresh.');
        }
        setProcessing(false);
      })();
    }, []);

    const handleCardSetupSuccess = () => {
      history.push(onCompleteRedirectPath);
    };

    if (processing) {
      return <Spinner></Spinner>;
    }

    if (error) {
      return <Paragraph type="danger">{error}</Paragraph>;
    }

    return (
      <>
        <Typography.Title level={4}>Enter payment details:</Typography.Title>
        <CardInput
          clientSecret={setupIntentSecret}
          onSuccess={handleCardSetupSuccess}
          successMessage="Successfully updated default payment method"
          initialSetup></CardInput>
      </>
    );
  },
);
