import 'react-phone-number-input/style.css';
import styled, { css } from 'styled-components';
import { Modal } from 'antd';

import { useContext, useState } from 'react';
import PhoneInput, {
  isPossiblePhoneNumber,
} from 'react-phone-number-input/input';

import { KioskHeader } from '../kiosk_header/kiosk_header';
import { NumberPad } from './number_pad/number_pad';
import { ApiClient } from '../../../api_client/api_client';
import { LocationContext } from '../../../contexts/location/provider';
import { KioskButton, KioskButtonColor } from '../kiosk_button/kiosk_button';
import { KioskLabel } from '../kiosk_label/kiosk_label';
import { TicketDisplay } from '../ticket_display/ticket_display';

const apiClient = new ApiClient();

const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: 2rem;
    grid-template-areas:
      'phone-input-container'
      'notes-container'
      'submit-button-container';

    @media (min-width: ${theme.breakpoints.desktop}) {
      grid-template-areas:
        'number-pad-container  phone-input-container'
        'number-pad-container  notes-container'
        'number-pad-container  submit-button-container';
      grid-template-rows: max-content max-content 1fr;
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

const PhoneInputContainer = styled.div`
  grid-area: phone-input-container;
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const NumberPadContainer = styled.div`
  grid-area: number-pad-container;
  display: none;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.desktop}) {
      max-width: 400px;
      display: block;
    }
  `}
`;

const NotesContainer = styled.div`
  grid-area: notes-container;
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const SubmitButtonContainer = styled.div`
  grid-area: submit-button-container;
`;

const NotesInput = styled.textarea`
  padding: 1rem;
  width: 100%;
  border-radius: 3px;
  border: 2px solid #bbb;
  border-radius: 0.25rem;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const ModalLabel = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.body};
    font-weight: ${theme.typography.weights.bold};
    text-transform: uppercase;
    letter-spacing: ${theme.typography.spacing.label};
    margin: 0;
    color: ${theme.colors.hint};
  `}
`;

const ModalNotes = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.body};
    margin: 0;
    font-weight: ${theme.typography.weights.bold};
  `}
`;

const ModalFootnote = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.body};
  `}
`;

export const CreateTicket: React.FC = () => {
  const [locationState] = useContext(LocationContext);
  const [phoneValue, setPhoneValue] = useState('');
  const [ticket, setTicket] = useState('');
  const [notes, setNotes] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onNumberPadClick = (value: number) => {
    setPhoneValue(`${phoneValue ? phoneValue : '+1'}${value}`);
  };

  const onPhoneInputChange = (value: string) => {
    setPhoneValue(value);
  };

  const onDeleteClick = () => {
    const newValue = phoneValue.slice(0, phoneValue.length - 1);
    setPhoneValue(newValue === '+1' ? '' : newValue);
  };

  const onSubmit = async () => {
    const ticket = await apiClient.createTicket({
      locationId: locationState.currentLocationId,
      phoneNumber: phoneValue,
      notes,
    });
    setIsModalVisible(true);
    setTicket(ticket);
  };

  const onCancel = () => {
    setIsModalVisible(false);
    setPhoneValue('');
    setNotes('');
    setTicket('');
  };

  return (
    <div>
      <KioskHeader heading={'Create Ticket'}></KioskHeader>
      <Container>
        <NumberPadContainer>
          <NumberPad
            onClick={onNumberPadClick}
            showDelete={Boolean(phoneValue && phoneValue.length > 0)}
            onDeleteClick={onDeleteClick}></NumberPad>
        </NumberPadContainer>
        <PhoneInputContainer>
          <KioskLabel>Phone Number:</KioskLabel>
          <PhoneInput
            style={{
              width: '100%',
              padding: '0.5rem 1rem',
              fontSize: '1.8rem',
              border: '2px solid #bbb',
              borderRadius: '.25rem',
            }}
            onChange={onPhoneInputChange}
            country="US"
            value={phoneValue}></PhoneInput>
          <KioskLabel error>
            {phoneValue ? (
              isPossiblePhoneNumber(phoneValue) ? <span>&nbsp;</span> : (
                <span>Invalid phone number</span>
              )
            ) : (
              <span>Phone number required</span>
            )}
          </KioskLabel>
        </PhoneInputContainer>
        <NotesContainer>
          <KioskLabel>Notes:</KioskLabel>
          <NotesInput
            rows={4}
            onChange={e => void setNotes(e.target.value)}
            value={notes}
          />
        </NotesContainer>
        <SubmitButtonContainer>
          {phoneValue && isPossiblePhoneNumber(phoneValue) && (
            <KioskButton
              onClick={onSubmit}
              buttonColor={KioskButtonColor.SUCCESS}
              extraPadding>
              Create Ticket
            </KioskButton>
          )}
        </SubmitButtonContainer>
      </Container>
      <Modal
        footer={null}
        closable={false}
        visible={isModalVisible}
        onCancel={onCancel}>
        <ModalContent>
          <ModalLabel>Ticket:</ModalLabel>
          <TicketDisplay text={ticket}></TicketDisplay>
          <ModalFootnote>(Take note of this ticket number.)</ModalFootnote>
          <ModalLabel>Notes:</ModalLabel>
          <ModalNotes>{notes}</ModalNotes>
        </ModalContent>
      </Modal>
    </div>
  );
};
