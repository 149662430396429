import { SIGN_UP_TOKEN_KEY, VALID_SIGN_UP_TOKEN } from '../../../constants/url_constants';
import { useQuery } from '../../../hooks/query';
import { ClosedBeta } from '../../closed_beta/closed_beta';

export const HasValidSignupToken: React.FC = ({children}) => {
  const query = useQuery();

  if (query.get(SIGN_UP_TOKEN_KEY) !== VALID_SIGN_UP_TOKEN) {
    return <ClosedBeta></ClosedBeta>;
  }

  return <>{children}</>;
};