import styled from 'styled-components';
import { FormEvent, useCallback, useState } from 'react';
import { Form, Input, Space, Typography } from 'antd';
import { Button } from 'antd';
import { withRouter } from 'react-router';

import { InputLabel } from '../input_label/input_label';
import { InputContainer } from '../input_container/input_container';
import { ApiClient } from '../../api_client/api_client';
import { storeTokenAndRedirect } from '../../utils/auth_util';
import { AuthPage } from '../auth_page/auth_page';
import { Center } from '../center/center';
import { Link } from 'react-router-dom';

const apiClient = new ApiClient();

const Buttons = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 1rem;
`;

export const Login = withRouter(({ history }) => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [inFlight, setInFlight] = useState(false);

  const handleSubmit = async () => {
    setInFlight(true);
    const token = await apiClient.login(usernameOrEmail, password);
    if (!token) {
      setError('Invalid login credentials.');
      setInFlight(false);
    } else {
      storeTokenAndRedirect(token, history);
    }
  };

  const canSubmit = useCallback(() => {
    if (usernameOrEmail.length === 0) return false;
    if (password.length === 0) return false;
    return true;
  }, [usernameOrEmail, password]);

  return (
    <AuthPage>
      <Center>
        <Typography.Title level={4}>Login</Typography.Title>
      </Center>
      <Form onFinish={handleSubmit}>
        <InputContainer>
          <InputLabel htmlFor="username-or-email">
            Username or Email:
          </InputLabel>
          <Input
            size="large"
            id="username-or-email"
            value={usernameOrEmail}
            onChange={e => void setUsernameOrEmail(e.target.value)}></Input>
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="password">Password:</InputLabel>
          <Input.Password
            size="large"
            id="password"
            value={password}
            onChange={e => void setPassword(e.target.value)}></Input.Password>
        </InputContainer>
        <Buttons>
          <Button
            loading={inFlight}
            disabled={!canSubmit()}
            type="primary"
            size="large"
            htmlType="submit">
            Login
          </Button>
          <Button
            type="link"
            size="large"
            htmlType="button"
            onClick={(e: FormEvent) => {
              history.push('/signup');
            }}>
            Sign up
          </Button>
        </Buttons>
        {error && (
          <Typography.Paragraph style={{ textAlign: 'right' }} type="danger">
            {error}
          </Typography.Paragraph>
        )}
      </Form>
      <Center>
        <Link to="/reset_password">Forgot your password?</Link>
      </Center>
    </AuthPage>
  );
});
