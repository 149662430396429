import { Typography } from "antd";
import { useParams } from "react-router-dom";
import { getRenderablePartner, isValidPartner } from "../../../utils/partner_util";
import { AuthPage } from "../../auth_page/auth_page";
import { ClosedBeta } from "../../closed_beta/closed_beta";
import { SignupForm } from "../signup_form/signup_form";

export const PartnerSignup: React.FC = () => {
  const { partnerName } = useParams<{ partnerName: string }>();
  if (!isValidPartner(partnerName)) {
    return <AuthPage>
      <Typography.Title level={4}>Invalid Partner</Typography.Title>
      <Typography.Paragraph>This URL looks incorrect. There was either a typo or the partner does not exist.</Typography.Paragraph>
    </AuthPage>
  }
  return <SignupForm partner={getRenderablePartner(partnerName)}></SignupForm>;
};