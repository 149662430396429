export enum KnownMessageType {
  ORDER_READY = 'order_ready',
  FOLLOW_UP = 'follow_up',
  ISSUE_WITH_ORDER = 'issue_with_order',
}
export const KNOWN_MESSAGE_TYPES = new Set<KnownMessageType>([
  KnownMessageType.ORDER_READY,
  KnownMessageType.FOLLOW_UP,
  KnownMessageType.ISSUE_WITH_ORDER,
]);

export enum HttpStatusCodes {
  UNAUTHENTICATED = 401,
  NOT_FOUND = 404,
}

export interface CardDetails {
  id: string;
  name: string;
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
  default: boolean;
}

// These come from https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export enum StripeSubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

// These come from https://stripe.com/docs/api/invoices/object#invoice_object-status.
export enum InvoiceStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
}

export interface Invoice {
  id: string;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  total: number;
  currency: string;
  periodEnd: number;
  periodStart: number;
  status: InvoiceStatus;
  hosted_invoice_url?: string;
  // The total number of messages used within the billing period represented
  // by this invoice.
  totalUsage: number;

  // Custom param that indicates where the invoice is open and payable
  // (finalized). This isn't 100% necessary, but the explicitness should help
  // clarify as oppoed to implicitly using `status` and `hosted_invoice_url`.
  upcoming?: boolean;
}

export enum OnboardStatus {
  NONE = 'none',
  PAYMENT_METHOD_MISSING = 'payment_method_missing',
  ONBOARDED = 'onboarded',
}

export interface MerchantApiObject {
  username: string;
  name: string;
  email: string;
  orderReadyMessage: string;
  followUpMessage: string;
  issueWithOrderMessage: string;
  subscriptionStatus: StripeSubscriptionStatus;
  willCancelAtPeriodEnd: boolean;
  trialCredits: number;
}

export enum CreateMerchantStatus {
  SUCCESS = 'success',
  FAILED_USERNAME_EXISTS = 'failed_username_exists',
  FAILED_EMAIL_EXISTS = 'failed_email_exists',
  FAILED_UNKNOWN = 'failed_unknown',
}

export interface LocationApiObject {
  _id: string;
  createdAt: string;
  merchantId: string;
  name: string;
  notes: string;
  updatedAt: string;
}

export interface MessageApiObject {
  _id: string;
  createdAt: string;
  updatedAt: string;
  locationId: string;
  type: string;
  content: string;
}

export enum TicketState {
  OPEN = 'open',
  PENDING_PICKUP = 'pending_pickup',
  ARCHIVED = 'archived',
}

export enum MessageDeliveryStatus {
  UNSET = 'UNSET',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  DELIVERY_UNKNOWN = 'DELIVERY_UNKNOWN',
  FAILED = 'FAILED',
  UNDELIVERED = 'UNDELIVERED',
}

export interface TicketApiObject {
  _id: string;
  createdAt: string;
  locationId: string;
  notes: string;
  phoneNumber: string;
  state: TicketState;
  ticket: string;
  updatedAt: string;
  hasBeenNotified: boolean;
  hasBeenFollowedUp: boolean;
  hasIssue: boolean;
  messageDeliveryStatus: MessageDeliveryStatus;
}

export enum ExportOptionColumn {
  CREATED_DATE = 'createdDate',
  TICKET = 'ticket',
  PHONE = 'phone',
  NOTES = 'notes',
  LOCATION = 'location',
}

export enum UpdateTicketResult {
  SUCCESS = 'SUCCESS',
  FAILED_UNKNOWN = 'FAILED_UNKNOWN',
  FAILED_VALIDATION = 'FAILED_VALIDATION',
  FAILED_TO_SEND_MESSAGE_INACTIVE_SUBSCRIPTION = 'FAILED_TO_SEND_MESSAGE_INACTIVE_SUBSCRIPTION',
  FAILED_TO_SEND_MESSAGE_UNKNOWN = 'FAILED_TO_SEND_MESSAGE_UNKNOWN',
}

export enum MarketPartner {
  NONE = 'none',
  RODEO = 'rodeo',
}
