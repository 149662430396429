import styled, { css, ThemeContext } from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from '../../logo/logo';
import { Center } from '../../center/center';
import { Button } from 'antd';
import { useCallback, useContext, useState } from 'react';

const Root = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.dashboardVerticalGutter} 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  `}
`;

const MobileExpand = styled.div`
  ${({ theme }) => css`
  margin-top: .5rem;
    @media (min-width: ${theme.breakpoints.desktop}) {
      display: none;
    }
  `}
 `;

const NavigationContainer = styled.div<{ expanded: boolean }>`
  ${({ theme, expanded }) => css`
    display: none;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: 1rem;

    ${expanded && css`
      display: flex;
    `}

    @media (min-width: ${theme.breakpoints.desktop}) {
      display: flex;
    }
  `}
`;

const NavigationItem = styled.div<{ active?: boolean }>`
  ${({ theme, active = false }) => css`
    color: ${theme.colors.surface};
    padding: 1rem 1.5rem;

    ${active &&
    css`
      background: ${theme.colors.headline};
    `}
  `}
`;

const LogoContainer = styled.div`
  width: 100%;
  padding: 0 1.5rem;
`;

export const DashboardNavigation: React.FC = () => {
  const location = useLocation();
  const theme = useContext(ThemeContext);
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <Root>
      <LogoContainer>
        <Link to="/">
          <Logo color="surface" width="8rem"></Logo>
        </Link>
      </LogoContainer>
      <MobileExpand>
        <Center>
          <Button style={{ color: theme.colors.surface, fontSize: '1.125rem' }} type="text" onClick={handleToggleExpand}>
            <MenuOutlined/>
          </Button>
        </Center>
      </MobileExpand>
      <NavigationContainer expanded={expanded}>
        <div>
          <Link to="/dashboard/messages">
            <NavigationItem active={location.pathname === '/dashboard/messages'}>
              Messages
            </NavigationItem>
          </Link>
          <Link to="/dashboard/locations">
            <NavigationItem active={location.pathname === '/dashboard/locations'}>
              Locations
            </NavigationItem>
          </Link>
          <Link to="/dashboard/account">
            <NavigationItem active={location.pathname === '/dashboard/account'}>
              Account
            </NavigationItem>
          </Link>
          <Link to="/dashboard/export">
            <NavigationItem active={location.pathname === '/dashboard/export'}>
              Export
            </NavigationItem>
          </Link>
        </div>
        <div>
          <Link to="/">
            <NavigationItem>
              App
            </NavigationItem>
          </Link>
          <a href="https://swiftexpo.io/getting-started/" target="_blank" rel="noreferrer">
            <NavigationItem>
              Getting Started
            </NavigationItem>
          </a>
          <a href="https://swiftexpo.io/privacy-policy/" target="_blank" rel="noreferrer">
            <NavigationItem>
              Privacy Policy
            </NavigationItem>
          </a>
          <Link to="/logout">
            <NavigationItem>
              Logout
            </NavigationItem>
          </Link>
        </div>
      </NavigationContainer>
    </Root>
  );
};
