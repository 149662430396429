import styled from 'styled-components';
import { assert } from 'assert-ts';
import { useState, SyntheticEvent } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { SetupIntent, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Button, Typography, Input, notification } from 'antd';
import { ApiClient } from '../../../api_client/api_client';

const { Paragraph } = Typography;

const CardContainer = styled.div`
  border: 1px solid #d9d9d9;
  height: 2.5rem;
  display: flex;
  align-items: center;
`;

const CardElementWrapper = styled.div`
  flex-grow: 1;
  margin: 0 0.6875rem;
`;

const apiClient = new ApiClient();

export interface CardInputProps {
  clientSecret: string;
  onSuccess: () => void;
  successMessage: string;
  initialSetup?: boolean;
}

export const CardInput: React.FC<CardInputProps> = ({
  clientSecret,
  onSuccess,
  successMessage,
  initialSetup = false,
}) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [billingName, setBillingName] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handleNameChange = (event: SyntheticEvent) => {
    setBillingName((event.target as HTMLInputElement).value);
  };
  const handleChange = async (event: StripeCardElementChangeEvent) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };
  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setProcessing(true);
    const payload = await assert(stripe).confirmCardSetup(clientSecret, {
      payment_method: {
        card: assert(elements?.getElement(CardElement)),
        billing_details: {
          name: billingName,
        },
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      updateCustomerDefaultPaymentMethod(payload.setupIntent);
    }
  };

  const updateCustomerDefaultPaymentMethod = async (
    setupIntent: SetupIntent,
  ) => {
    if (!setupIntent.payment_method) {
      console.error('No payment method to use.');
      return;
    }

    const success = await apiClient.updateStripeCustomerPaymentMethod(
      setupIntent.payment_method,
    );
    if (success) {
      notification.success({
        message: successMessage,
      });
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      onSuccess();
    } else {
      setError('Error updating default payment method. Please try again');
      setProcessing(false);
      setSucceeded(false);
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#bfbfbf',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <div>
      <Paragraph>
        <Input
          onChange={handleNameChange}
          value={billingName}
          size="large"
          placeholder="Full Name"></Input>
      </Paragraph>
      <Paragraph>
        <CardContainer>
          <CardElementWrapper>
            <CardElement
              id="card-element"
              options={cardStyle}
              onChange={handleChange}
            />
          </CardElementWrapper>
        </CardContainer>
      </Paragraph>
      <Paragraph>
        <Button
          size="large"
          type="primary"
          disabled={processing || disabled || succeeded}
          id="submit"
          loading={processing}
          onClick={handleSubmit}>
          {initialSetup ? 'Set up automatic payments' : 'Add payment method'}
        </Button>
      </Paragraph>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
    </div>
  );
};
