import styled from 'styled-components';
import { Typography, Button, Card } from 'antd';
import assert from 'assert-ts';
import { PricingTable } from './pricing_table/pricing_table';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useContext, useState } from 'react';

import { ApiClient } from '../../api_client/api_client';
import { SetupPayment } from './setup_payment/setup_payment';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AdminProtected } from '../admin_protected/admin_protected';
import { MerchantContext } from '../../contexts/merchant/provider';
import { OnboardStatus } from '../../declarations';
import { fetchAndSetOnboardStatus } from '../../contexts/merchant/async_actions';
import { MerchantActionType } from '../../contexts/merchant/actions';
import { Loading } from '../dashboard/loading/loading';
import { useQuery } from '../../hooks/query';
import { PRICE_CONFIG_CODE_QUERY_KEY } from '../../constants/url_constants';

const { Title, Paragraph, Text } = Typography;

const stripePromise = loadStripe(
  assert(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
);

const apiClient = new ApiClient();

const Root = styled.div`
  height: 100%;
  width: 100%;
  padding: 5rem 0;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 42.5rem;
  width: 100%;
`;

export const Onboard: React.FC = () => {
  const [merchantState, merchantDispatch] = useContext(MerchantContext);
  const [inFlight, setInFlight] = useState(false);
  const [startingCredits, setStartingCredits] = useState(0);
  const [error, setError] = useState('');
  const [initializationError, setInitializationError] = useState('');
  const query = useQuery();

  const fetchAndSetStartingCredits = async () => {
    const { result, success } = await apiClient.fetchStartingCredits();
    if (!success) {
      setInitializationError(
        'Unable to initialize onboarding. Please try again.',
      );
      return;
    }
    setStartingCredits(result);
  };

  useEffect(() => {
    fetchAndSetOnboardStatus(merchantDispatch);
  }, [merchantDispatch]);

  useEffect(() => {
    if (startingCredits !== 0) return;
    fetchAndSetStartingCredits();
  }, [startingCredits, setStartingCredits]);

  const handleRegisterButtonClick = async () => {
    setInFlight(true);
    setError('');
    const succeeded = await apiClient.createStripeSubscription();
    setInFlight(false);
    if (succeeded) {
      merchantDispatch({
        type: MerchantActionType.SET_ONBOARD_STATUS,
        status: OnboardStatus.PAYMENT_METHOD_MISSING,
      });
    } else {
      setError(
        'Error initializing payment input. Please refresh and try again.',
      );
    }
  };

  if (merchantState.onboardStatus === OnboardStatus.ONBOARDED) {
    return <Redirect to="/dashboard"></Redirect>;
  }

  if (initializationError) {
    return <Paragraph type="danger">{initializationError}</Paragraph>;
  }

  if (startingCredits === 0) {
    return <Loading></Loading>;
  }

  return (
    <AdminProtected>
      <Elements stripe={stripePromise}>
        <Root>
          <Content>
            <Title>Welcome to SwiftExpo!</Title>
            <Paragraph>
              This app allows you to configure text messages that you want to
              send to your customers, and send those notifications when you want
              using a mobile or tablet-friendly interface.
            </Paragraph>
            <Paragraph>
              You can try out the app risk-free for one month or up to {startingCredits} free
              messaging credits, whichever is used up first.
            </Paragraph>
            <Paragraph>
              Here's the pricing table for any subsequent usage:
            </Paragraph>
            <PricingTable startingCredits={startingCredits}></PricingTable>
            <Paragraph>
              To get started, you'll need to subscribe to metered billing for
              usage of the app by entering credit card information. We'll charge
              this credit card at the end of each billing cycle with the
              appropriate amount based on usage.
            </Paragraph>
            <Paragraph>
              {(() => {
                if (merchantState.onboardStatus === OnboardStatus.NONE) {
                  return (
                    <div>
                      <Paragraph>
                        <Text strong>
                          By clicking "Register" below, you agree to our&nbsp;
                          <a
                            href="https://swiftexpo.io/terms-of-service/"
                            target="_blank">
                            Terms and Conditions
                          </a>
                          .
                        </Text>
                      </Paragraph>
                      <Paragraph>
                        <Button
                          loading={inFlight}
                          onClick={handleRegisterButtonClick}
                          size="large"
                          type="primary">
                          Register
                        </Button>
                      </Paragraph>
                    </div>
                  );
                }

                if (error) {
                  return <Text type="danger">{error}</Text>;
                }

                return (
                  <Card>
                    <SetupPayment onCompleteRedirectPath="/dashboard/account"></SetupPayment>
                  </Card>
                );
              })()}
            </Paragraph>
          </Content>
        </Root>
      </Elements>
    </AdminProtected>
  );
};
